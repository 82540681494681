.dark-theme {
    --background-color: #232b3e;
    --background-color-div: #1a202e;
    --text-color: #fff;
    --background-hover-color: #10131c;
}
.light-theme {
    --background-color: #f1f2f7;
    --background-color-div: #fff;
    --text-color: #000;
    --background-hover-color: #f0f3fb;
}

.sidebar-main {
    width: 20%;
    height: 100vh;
    background-color: var(--background-color-div);
    /* background-color: violet; */
    position: fixed;   
    overflow-y: scroll;
    /* display: none; */
    align-items: center;
    align-items: center;
    /* flex-direction: column; */
    margin-top: 40px;
    color: var(--text-color);
}
.sidebar-main1{
    position: fixed;
    top: 60px;
    left: -100%; 
    width: 250px;
    height: 100vh;
    background-color: var(--background-color-div);
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    z-index: 100;
    overflow-y: scroll;
    color: var(--text-color);
}

.sidebar-main1.visible {
    transform: translateX(0); /* Slide into view */
}

.sidebar-main::-webkit-scrollbar {
    width: 6px;
    display: none;
}

.sidebar-main:hover::-webkit-scrollbar {
    display: block;
}
.sidebar-main::-webkit-scrollbar-thumb {
    background-color: #e3e3e3; /* Scrollbar color */
    border-radius: 5px; 
}
.sidebar-main1::-webkit-scrollbar {
    width: 6px;
    display: none;
}

.sidebar-main1:hover::-webkit-scrollbar {
    display: block;
}
.sidebar-main1::-webkit-scrollbar-thumb {
    background-color: #e3e3e3; /* Scrollbar color */
    border-radius: 5px; 
}
.sidebar-content-main{
    width: 100%;
    height:570px;
    /* background-color: tan; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* flex-direction: column; */
    gap: 5px;
    margin-top: 50px;
    padding-bottom: 20px ;
}
.dash-p{
    width: 85%;
    height: auto;
    /* background-color: aquamarine; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;

}
.active-link {
    background-color: var(--background-hover-color);
    color: #fff;
    border-radius: 8px;
  }
.dashboard{
    width: 100%;
    /* background-color: var(--background-hover-color); */
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    gap: 50px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    color: var(--text-color);
}
.dashboard:hover{
    background-color: var(--background-hover-color);
}
.dropdown {
    width: 100%;
    /* background-color: #cc5e5e; */
    /* border: 1px solid #ccc; */
    /* margin-top: 5px; */
    border-radius: 5px;
}

.p-tag{
    width: 150px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 10px;
    /* background-color: violet; */
}
.p-tag:hover{
    color: var(--text-hover-color);
}
.dash-p.selected {
    background-color: var(--background-hover-color); /* Gray background */
    border-radius: 5px;
}

.dash-p.selected .p-tag::before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--text-hover-color); /* Dot color */
    margin-right: 8px;
    display: inline-block;
}
.p-tag i{
    font-size: 16px;
}
.dropdown ul {
    list-style: circle;
    gap: 15px;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    /* justify-content: start; */
}

.dropdown ul li {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* margin-top: 5px; */
    /* background-color: #f0f3fb; */
    margin-left: -10%;
    gap: 10px;
    border-radius: 5px;
}
.dropdown ul li:hover{
    background-color: var(--background-hover-color); 
}
.dropdown ul li.selected {
    color: #545454;
  }
  
  .dropdown ul li.selected::before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #6777ef;
}
@media (max-width:1100px) {
    .sidebar-main{
        display: none;
    }
    .sidebar-main1{
        left: 0;
    }
    .sidebar-content-main{
        margin-top: 20px;
    }
      
}
