.dark-theme {
    --background-color: #232b3e;
    --background-color-div: #1a202e;
    --text-color: #fff;
    --background-hover-color: #10131c;
}
.light-theme {
    --background-color: #f1f2f7;
    --background-color-div: #fff;
    --text-color: #000;
    --background-hover-color: #f0f3fb;
}

.raw-main{
    width: 80%;
    height: 100%;
    background-color: var(--background-color);
    margin-left: 20%;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;

}
.raw-content-main{
    width: 95%;
    height: auto;
    background-color: var(--background-color-div);
    margin-top: 50px;
    border-radius: 20px ;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12);

}
.raw-content-nav{
    width: 100%;
    height: 70px;
    background-color: var(--device-hover);
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 10px; */
}
.raw-content-head{
    /* background-color: yellow; */
    display: flex;
    margin-left: 10px;
}
.raw-content-items{
    width: 280px;
    display: flex;
    /* background-color: teal; */
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0 10px;
}
.raw-content-items i{
  cursor: pointer;
}
.raw-add-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.268);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .raw-add-popup-content {
    background-color: var(--pop-up);
    padding: 20px;
    border-radius: 8px;
    width: 700px;
    height: 600px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    /* gap: 5px; */
    display: flex;
    flex-direction: column;
  }
  .raw-add-popup-close{
    margin-left: 95%;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
  }
  .raw-add-popup-save{
    width: 100px;
    height: 40px;
    margin-top: 30px;
    background-color: #005cbb;
    /* outline: none; */
    border: none;
    color: white;
    border-radius: 20px;
    cursor: pointer;
  }
  .raw-add-input-main{
    width: 100%;
    height: 450px;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
  }
  .raw-add-input-main::-webkit-scrollbar{
    width: 4px;
  }
  .raw-add-input-main::-webkit-scrollbar-thumb{
    color: var(--text-color);
    background-color: var(--text-color);
  }
  .raw-add-img{
    width: 70%;
    height: 60px;
    background-color: var(--background-hover-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border-radius: 10px;
  }
  .raw-add-input{
    width: 70%;
    height: 60px;
    /* background-color: var(--background-hover-color); */
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .raw-history-popup-content{
    background-color: var(--pop-up);
    padding: 20px;
    border-radius: 8px;
    width: 700px;
    height: 300px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  .raw-history-popup-table{
    width: 100%;
    height: 250px;
    /* background-color: yellow; */
    overflow-y: scroll;
  }
   .raw-history-popup-table::-webkit-scrollbar{
    width: 1px;
  }
  .raw-history-popup-table::-webkit-scrollbar-thumb{
    background-color: var(--text-color);
  }
  .raw-change-img-btn{
    width: 120px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #005cbb;
    cursor: pointer;
  }

  /* ..................................................................................... Edit Data .................................................................. */
.raw-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.268);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .raw-popup-content {
    background-color: var(--pop-up);
    /* padding: 20px; */
    padding: 30px;
    border-radius: 8px;
    width: 450px;
    height: 450px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);

  }
  .raw-popup-close{
    margin-left: 95%;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
  }
  .raw-popup-image{
    width: 70%;
    height: 60px;
    display: flex;
    background-color: var(--background-color-div);
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
  }
  .raw-popup-input{
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* background-color: #ff1a1a; */
    /* padding: 0 20px; */
  }
  .feature-edit-popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.268);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  .feature-edit-popup-content{
    background-color: var(--pop-up);
    /* padding: 20px; */
    padding: 30px;
    border-radius: 8px;
    width: 450px;
    height: 300px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }

.feature-edit-popup-input-main{
  width: 95%;
  height: 220px;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  padding: 20px 0;

}
.feature-edit-popup-input-main::-webkit-scrollbar{
  width: 4px;
}
.feature-edit-popup-input-main::-webkit-scrollbar-thumb{
  background-color: var(--text-color);
}
.raw-feature-edit-btn{
  width: 100px;
  height: 35px;
  background-color: #005cbb;
  border-radius: 20px;
  border: none;
  color: white;
  cursor: pointer;
  margin-top: 20px;
}

.feature-edit-popup-save{
  width: 100px;
  height: 35px;
  background-color: #005cbb;
  border-radius: 20px;
  border: none;
  color: white;
  cursor: pointer;
}

.raw-feature-edit-popup-content{
  background-color: var(--pop-up);
  /* padding: 20px; */
  padding: 30px;
  border-radius: 8px;
  width: 450px;
  height: 400px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.feature-delete-popup-list-main{
  width: 90%;
  height: 200px;
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}
.feature-delete-popup-list-main::-webkit-scrollbar{
  width: 2px;
}
.feature-delete-popup-list-main::-webkit-scrollbar-thumb{
  background-color: var(--text-color);
}

.feature-delete-popup-list-main ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: violet; */
  list-style-type: none;
}

.feature-delete-popup-list-main ul li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.custom-dropdown {
  position: relative;
  width: 100%;
  /* background-color: yellow; */
}

.dropdown-header {
  background:  var(--background-hover-color);
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  background: var(--background-hover-color);
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s;
}

/* .dropdown-item:hover {
  background: #f0f0f0;
} */

.raw-add-feature-input{
  width: 95%;
  height: 150px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* align-items: center; */
  justify-content: center;
  /* background-color: yellow; */
}
.feature-input{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.raw-feature-add-button{
  width: 100px;
  height: 35px;
  border: none;
  color: white;
  background-color: #005cbb;
  border-radius: 20px;
}

.raw-stock-edit-input-main{
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 25px;
}
.raw-stock-edit-input{
  /* background-color: wheat; */
  display: flex;
  flex-direction: column;
}
.raw-stock-edit-btn{
  width: 100px;
  height: 35px;
  background-color: #005cbb;
  border: none;
  border-radius: 20px;
  color: white;
}

@media (max-width:1100px) {
    .raw-main{
        width: 100%;
        margin-left: 0;
    }
}