.light-theme {
  --background-color: #f1f2f7;
  --background-color-div: #fff;
  --text-color: #000;
  --chart-popup1: #bcbcbc;
  --chart-popup2: #fff;
}

/* Dark Theme */
.dark-theme {
  --background-color: #232b3e;
  --background-color-div: #1a202e;
  --text-color: #fff;
  --chart-popup1: #232b3e;
  --chart-popup2: #1a202e;
}
body {
  margin: 0;
  background-color: var(--background-color);
}