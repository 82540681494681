.light-theme {
  --background-color: #f1f2f7;
  --background-color-div: #fff;
  --text-color: #000;
  --device-hover: #dae1f3;
}

/* Dark Theme */
.dark-theme {
  --background-color: #232b3e;
  --background-color-div: #1a202e;
  --text-color: #fff;
  --device-hover: #020910;
}


.thing-main {
  width: 80%;
  height: 100%;
  background-color: var(--background-color);
  margin-left: 20%;
  color: var(--text-color);
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.thing-content-main {
  width: 95%;
  height: auto;
  background-color: var(--background-color-div);
  border-radius: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12);
  margin-top: 50px;
}

.thing-content {
  width: 100%;
  height: 70px;
  background-color: var(--device-hover);
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.thing-content-head {
  width: 120px;
  /* background-color: yellowgreen; */
  padding-left: 20px;
}

.thing-content-stock-deatails-main{
  width: 50%;
  /* background-color: teal; */
  display: flex;
}

.thing-content-stock-deatails {
  width: 100%;
  height: 50px;
  /* background-color: rgb(147, 147, 92); */
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  position: relative; /* For absolute positioning of dropdown */
  gap: 20px;
}


.thing-content-stock-product {
  width: 60%;
  height: 40px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.dropdown {
  position: absolute;
  top: 50px; /* Position below the product div */
  width: 60%;
  background-color: var(--background-color);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.dropdown ul {
  list-style: none;
  margin: 0;
  padding: 10px;
}

.dropdown ul li {
  padding: 5px 10px;
  margin-left: -10px;
  cursor: pointer;
}

/* .dropdown ul li:hover {
  background-color: rgb(230, 230, 230);
} */


.thing-content-items {
  width: 300px;
  height: 50px;
  position: relative;
  /* background-color: #ff4d4d; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  gap: 20px;
}

.thing-slide-menu {
  width: 150px;
  position: absolute;
  top: 50px;
  /* Adjust to place below the sliders icon */
  right: 0;
  /* Align to the left edge of the parent container */
  background-color: var(--background-color-div);
  /* background-color: #005cbb; */
  border: 1px solid #484848;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
  /* Hidden by default */
}

.thing-slide-menu.show {
  display: flex;
  /* Show when toggled */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  /* width: 200px; */
}

.thing-slide-menu::-webkit-scrollbar {
  width: 2px;
}

.thing-slide-menu::-webkit-scrollbar-thumb {
  background-color: #ff0000;
}

.thing-slide-item {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  /* cursor: pointer; */
  gap: 10px;
  transition: background-color 0.2s ease-in-out;
}

.thing-slide-item span {
  cursor: pointer;
}

/* .thing-slide-item:hover {
    background-color: #f5f5f5;
} */

.thing-slide-item i {
  color: grey;
  /* Icon color, can match your preferred theme */
  font-size: 18px;
}

.thing-slide-item span {
  font-size: 16px;
  color: var(--text-color);
  /* color: darkgreen; */
}

.fa-sliders {
  cursor: pointer;
}

.test-button {
  font-size: 20px;
  border: none;
  background-color: transparent;
  color: #005cbb;
  cursor: pointer;
}

.hDPlLz {
  border-radius: 20px;
}
.chYGqH{
  border-radius: 0;
}

.dZYcTC::-webkit-scrollbar {
  height: 7px;
  
}

.dZYcTC::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.514);
  cursor: pointer;

}
.eqGorF::-webkit-scrollbar {
  height: 7px;
}

.eqGorF::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.514);
  cursor: pointer;
}

.audit-popup-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.268);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.audit-popup-content-main{
  background-color: var(--pop-up);
  padding: 20px;
  border-radius: 8px;
  width: 70%;
  height: 80%;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.audit-popup-content{
  width: 90%;
  height: 95%;
  /* background-color: aqua; */
  display: flex;
  gap: 20px;
}
.audit-popup-btn-details{
  width: 43%;
  height: 100%;
  /* background-color: yellowgreen; */
}
.audit-popup-btn-main{
  width: 100%;
  height: 70%;
  /* background-color: #005cbb; */
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  flex-direction: column;
  gap: 30px;
  overflow-y: scroll;
  padding: 10px ;
  border-right: 1px solid gray;
}
.audit-popup-btn-main::-webkit-scrollbar {
  width: 1px;
}

.audit-popup-btn-main::-webkit-scrollbar-thumb {
  color: rgb(255, 255, 255);

}
.eqGorF::-webkit-scrollbar {
  width: 1px;
}

.eqGorF::-webkit-scrollbar-thumb {
  color: rgb(255, 255, 255);

}
.audit-popup-wifi-main{
  width: 100%;
  height: 150px;
  /* background-color: rgb(177, 51, 51); */
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
}
.audit-popup-wifi-main::-webkit-scrollbar {
  width: 1px;
}

.audit-popup-wifi-main::-webkit-scrollbar-thumb {
  color: rgb(255, 255, 255);

}
.audit-popup-wifi-details{
  width: 90%;
  height: 30px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  padding: 0 10px;
}
.audit-popup-btn-main button{
  width: 90px;
  height: 90px;
  font-size: 30px;
  border-radius: 10px;
  outline: none;
  border: 2px solid rgb(111, 255, 0) ;
  cursor: pointer;
}
.audit-popup-state-details{
  width: 57%;
  height: 100%;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
}
.audit-popup-state-main{
  width: 90%;
  height: 70%;
  background-color: var(--background-color-div);
  padding: 10px 0;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.dZYcTC::-webkit-scrollbar {
  width: 1px;
}

.dZYcTC::-webkit-scrollbar-thumb {
  color: rgb(255, 255, 255);

}


@media (max-width:1100px) {
  .thing-main {
    width: 100%;
    margin-left: 0;
  }
}