.light-theme {
    --background-color: #f1f2f7;
    --background-color-div: #fff;
    --text-color: #000;
    --chart-popup1: #bcbcbc;
    --chart-popup2: #fff;
  }
  
  /* Dark Theme */
  .dark-theme {
    --background-color: #232b3e;
    --background-color-div: #1a202e;
    --text-color: #fff;
    --chart-popup1: #232b3e;
    --chart-popup2: #1a202e;
  }

.valid-main {
    width: 100%;
    height: 100vh;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    gap: 30px;
    /* padding: 20px 0; */
}

.valid-main img {
    width: 10rem;
}

.valid-content-main {
    width: 400px;
    /* height: 450px; */
    border: 1px solid gray;
    border-radius: 10px;
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-direction: column;
    gap: 30px;
}

.valid-login-input {
    width: 100%;
    height: 200px;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
}
.valid-resetpass-login{
    width: 70%;
    /* background-color: violet; */
    display: flex;
    margin-top: -20px;
    align-items: start;
    justify-content: end;
}
.valid-login-input p{
    color: rgba(222, 222, 222, 0.752);
    cursor: pointer;
}
.valid-signup-input{
    width: 100%;
    height: 300px;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
}
.valid-buttons {
    width: 100%;
    height: 60px;
    /* background-color: violet; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.valid-buttons button {
    width: 150px;
    height: 40px;
    border: 1px solid rgb(213, 212, 212);
    color: white;
    background-color: transparent;
    outline: none;
    border-radius: 20px;
    cursor: pointer;
}
.valid-main-btn{
    width: 95%;
    height: 60px;
    /* background-color: yellow; */
    display: flex;
    justify-content: end;
    align-items: end;
    padding: 20px;
}
.valid-main-btn button{
    width: 80px;
    height: 30px;
    background-color: transparent;
    outline: none;
    border: 1px solid rgb(147, 147, 147);
    color: white;
    border-radius: 10px;
    cursor: pointer;
}
.valid-reset-password{
    width: 100%;
    height: 200px;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
}
.otp-popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.268);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
.otp-popup{
    background-color: rgb(46, 44, 44);
    /* padding: 20px; */
    padding: 70px 30px;
    border-radius: 8px;
    width: 350px;
    height: 150px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.otp-popup-input{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.otp-popup-resend p{
    cursor: pointer;
}