/* Light Theme */
.light-theme {
  --background-color: #f1f2f7;
  --background-color-div: #fff;
  --text-color: #000;
  --chart-popup1: #bcbcbc;
  --chart-popup2: #fff;
}

/* Dark Theme */
.dark-theme {
  --background-color: #232b3e;
  --background-color-div: #1a202e;
  --text-color: #fff;
  --chart-popup1: #232b3e;
  --chart-popup2: #1a202e;
}


*{
  /* font-family: "Blinker", serif; */
  font-family: "Roboto", serif;
  /* background-color: var(--background-color); */
  

}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
