.dark-theme {
    --background-color: #232b3e;
    --background-color-div: #1a202e;
    --text-color: #fff;
    --background-hover-color: #10131c;
}
.light-theme {
    --background-color: #f1f2f7;
    --background-color-div: #fff;
    --text-color: #000;
    --background-hover-color: #f0f3fb;
}

.nav-main{
    width: 100%;
    height: 60px;
    background-color: var(--background-color-div);
    /* box-shadow: 0 1px 5px #00000040; */
    position: fixed;
    /* margin: 0; */
    display: flex;
    /* justify-content: end; */
    /* align-items: end; */
    z-index: 100;
}
.nav-logo{
    width: 20%;
    height: 60px;
    background-color: var(--background-color-div);
    /* background-color: aquamarine; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav-logo img{
    width: 7rem;

}
.nav-content-main{
    width: 80%;
    height: 60px;
    /* background-color: aqua; */
    box-shadow: 3px 1px 5px #00000040;
    display: flex;
    justify-content: end;
}
.nav-content-bar{
    width: 100px;
    height: 60px;
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 0px; */
    display: none;
}
.nav-content-bar i{
    cursor: pointer;
    color: var(--text-color);
    font-size: 25px;
}
.nav-content-logo{
    width: auto;
    /* background-color: yellowgreen; */
    display: none;
    align-items: center;
    justify-content: center;
}
.nav-content-logo img{
    width: 7rem;
}
.nav-content{
    width: 200px;
    height: 60px;
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    gap: 30px;
    color: var(--text-color);
}
.nav-content img{
    cursor: pointer;
    border-radius: 50%;
}
.nav-profile {
    width: 30px;
    height: 30px;
    /* background-color: yellowgreen; */
    border: 1px solid rgb(213, 209, 209);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(213, 209, 209);
    font-size: 15px;
}
.nav-profile img{
    width: 2rem;
    height: 2rem;
}
.nav-popup-menu {
    width: 250px;
    height: 300px;
    position: absolute;
    top: 50px; /* Adjust to position below the profile icon */
    right: 10px; /* Adjust for alignment */
    background-color: var(--background-color-div);
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 6px 1px rgba(0, 0, 0, 0.495);
    /* padding: 10px; */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.notification-popup-menu{
    width: 400px;
    height: 300px;
    position: absolute;
    top: 50px; /* Adjust to position below the profile icon */
    right: 100px; /* Adjust for alignment */
    background-color: var(--background-color-div);
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 6px 1px rgba(0, 0, 0, 0.495);
    /* padding: 10px; */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 20px;
    gap: 20px;
}
.notification-popup-menu2{
    width: 100%;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
    gap: 20px;
    overflow-y: scroll;
}
.notification-popup-menu2::-webkit-scrollbar {
    width: 1px;
  }
  
  .notification-popup-menu2::-webkit-scrollbar-thumb {
    color: rgb(255, 255, 255);
  
  }

.notification-message-data{
    width: 90%;
    min-height: auto;
    background-color: var(--background-color);
    border-radius: 10px;
    padding: 20px;
    color: var(--text-color);
}
.notification-message-data.unread {
    background-color: var(--background-hover-color); /* Use your custom variable */
    font-weight: bold;
    cursor: pointer;
}
.notification-bar-btns{
    display: flex;
    /* background-color: yellow; */
    justify-content: space-between;
    font-size: 13px;
    align-items: center;
    color: var(--text-color);
}
.notification-bar-btns button{
    width: 80px;
    height: 30px;
    background-color: #005cbb;
    border-radius: 20px;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
}

.notification-badge {
    position: absolute;
    top: -5px;
    right: -6px;
    background: red;
    color: white;
    font-size: 10px;
    font-weight: bold;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--background-color-div); /* Creates a nice white border */
}
.nav-popup-profile1{
    width: 100%;
    height: 70px;
    background-color: rgb(24, 103, 167);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
}
.nav-popup-profile1 p{
    color: rgb(140, 177, 207);
    margin-bottom: 15%;
}
.nav-popup-profile2{
    width: 100%;
    height: 40px;
    /* background-color: rgb(1, 135, 245); */
    margin-top: 28%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-popup-profile2 img{
   width: 4rem;
   height: 4rem;
   position: relative;
   border-radius: 50%;
   border: 2px solid var(--background-color-div);
   margin-bottom: 15%; 
}
.nav-popup-profile-details{
    width: 100%;
    height: 50px;
    /* background-color: violet; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    line-height: 5px;
    color: var(--text-color);
}
.nav-popup-item-main{
    width: 90%;
    height: 170px;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
.nav-popup-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    gap: 10px;
    transition: background-color 0.2s ease-in-out;
}

.nav-popup-item i {
    color: grey; 
    font-size: 18px;
    cursor: pointer;
}

.nav-popup-item span {
    font-size: 16px;
    color: var(--text-color);
    cursor: pointer;
}
.nav-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.268);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .nav-popup-content {
    background-color: var(--pop-up);
    padding: 20px;
    border-radius: 8px;
    width: 35%;
    height: 300px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
  }
  .nav-popup-close{
    margin-left: 98%;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
  }
  .nav-popup-items{
    width: 95%;
    height: 250px;
    /* background-color: yellowgreen; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 20px;
  }
  .nav-popup-edit-profile{
    width: 80%;
    height: 80px;
    background-color: var(--background-color-div);
    border-radius: 20px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
  }
  .nav-edit-profile{
    width: 250px;
    height: 50px;
    /* background-color: violet; */
    /* border-radius: 50%; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 20px;
    color: var(--text-color);
  }
  .nav-edit-profile img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  .nav-change-profile-btn{
    width: 150px;
    height: 40px;
    background-color: rgb(56, 56, 245);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
  }
  .nav-change-profile-btn label{
    cursor: pointer;
  }
  .cropper-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cropper-container {
    position: relative;
    width: 30%;
    height: 50%;
    background: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cropper-btn {
    /* margin-top: 10px; */
    width: 100%;
    height: 50px;
    z-index: 100;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cropper-close{
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: rgb(255, 255, 255);
}
.cropper-save{
    width: 60px;
    height: 30px;
    background-color: rgb(68, 68, 228);
    border: none;
    border-radius: 20px;
    color: white;
    cursor: pointer;
}
.nav-popup-edit-input{
    width: 80%;
    height: 80px;
    /* background-color: rgb(196, 0, 196); */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    color: var(--text-color);
    gap: 10px;
}
.nav-popup-edit-input input{
    width: 90%;
}
.nav-popup-save-btn{
    width: 100%;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-save{
    width: 100px;
    height: 35px;
    background-color: #005cbb;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
}

@media (max-width:1100px) {
    .nav-logo{
        display: none;
    }
    .nav-content-main{
        width: 100%;
        /* box-shadow: none; */
        justify-content: space-between;
        /* align-items: center; */
    }
    .nav-content-bar{
        display: flex;
        /* background-color: yellowgreen; */
    }
    .nav-content-logo{
        display: flex;
    }
}