/* Main button */
.set-main {
    width: 40px;
    height: 40px;
    background-color: #000000;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    cursor: pointer;
    bottom: 15%;
    right: 1%;
    transition: right 0.5s ease;
  }
  
  .set-main.slide {
    right: 305px; /* Moves along with the panel */
  }
  
  /* Sliding panel */
  .settings-panel {
    position: fixed;
    bottom: 15%;
    right: -300px; 
    width: 300px;
    height: auto;
    background-color: #f4f4f4;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 999;
    transition: right 0.5s ease;
  }
  
  /* Panel when open */
  .settings-panel.open {
    right: 0; /* Slide into view */
  }
  
  /* Content inside the sliding panel */
  .settings-panel p {
    padding: 20px;
    font-size: 16px;
    color: #333;
  }
  .settings-panel-light{
    width: 100%;
    height: 200px;
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
  }
  .settings-panel-light img{
    width: 14rem;
    margin-top: 30px;
    border: 2px solid gray;
    cursor: pointer;
    color: white;
  }
  .settings-panel-light p{
    margin-top: -10px;
  }