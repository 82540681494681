.light-theme {
  --background-color: #f1f2f7;
  --background-color-div: #fff;
  --text-color: #000;
  --pagination-color: rgba(0, 0, 0, .54);
  --pagination-fill: rgba(0, 0, 0, .54);
  --pop-up: #cfcfcf;
  --device-hover: #dae1f3;
}

/* Dark Theme */
.dark-theme {
  --background-color: #232b3e;
  --background-color-div: #1a202e;
  --text-color: #fff;
  --pagination-color: rgba(255, 255, 255, 0.54);
  --pagination-fill: rgba(255, 255, 255, 0.54);
  --pop-up: #232b3e;
  --device-hover: #020910;
}


.device-main {
  width: 80%;
  height: 100%;
  background-color: var(--background-color);
  /* background-color: yellowgreen; */
  margin-left: 20%;
  color: var(--text-color);
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.second-main {
  width: 100%;
  /* background-color: thistle; */
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.device-content-main {
  width: 95%;
  height: auto;
  /* background-color: yellowgreen; */
  background-color: var(--background-color-div);
  border-radius: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12);
  overflow: hidden;
  margin-top: 50px;
}

.device-content {
  width: 100%;
  height: 70px;
  background-color: var(--device-hover);
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.device-content-head {
  width: 130px;
  height: 50px;
  /* background-color: violet; */
  padding-left: 10px;
  color: var(--text-color);
}

.device-content-items {
  width: 320px;
  height: 50px;
  /* background-color: #ff4d4d; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  gap: 20px;
  padding-right: 10px;
}

.device-search {
  width: 70%;
  height: 40px;
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  border: 1px solid rgba(148, 148, 148, 0.497);
  border-radius: 20px;
  color: rgba(148, 148, 148, 0.497);
}

.device-search input {
  width: 80%;
  height: 100%;
  border: none;
  outline: none;
  color: var(--text-color);
}

.fa-square-plus {
  cursor: pointer;
}

.dZYcTC {
  border-radius: unset !important;
}

.eqGorF {
  border-radius: unset !important;
}

.hudJpJ {
  background-color: var(--background-color-div) !important;
}

.hxydeb {
  color: var(--text-color) !important;
  font-size: 15px !important;

}

.iGxMlw {
  color: var(--text-color) !important;
  background-color: var(--background-color-div) !important;
}

.hXGOzQ {
  color: var(--text-color) !important;
  background-color: var(--background-color-div) !important;
}

.hDPlLz {
  background-color: var(--background-color-div) !important;
  color: var(--text-color) !important;
}

.cNURWT {
  background-color: var(--background-color-div) !important;
  color: var(--text-color) !important;
}

.chYGqH {
  background-color: var(--background-color-div) !important;
  color: var(--text-color) !important;
}

.cPTFZC {
  color: var(--pagination-color) !important;
  fill: var(--pagination-fill) !important;
}

.fxznza {
  color: var(--pagination-color) !important;
  fill: var(--pagination-fill) !important;
}

.kfEVfC {
  display: none !important;
}

.bpcekY {
  display: none !important;
}

.device-action-btn {
  display: flex;
  /* background-color: #000; */
  /* justify-content: center;
  align-items: center; */
  gap: 10px;
}

.action-button {
  background: none;
  border: none;
  color: #005cbb;
  font-size: 1.2rem;
}

.fa-pen-to-square {
  /* margin-left: 20px; */
  color: #005cbb;
}

.action-button i {
  cursor: pointer;
}

.action-button i:hover {
  color: #ff1a1a;
}

.model-edit-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.268);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.model-edit-popup-content {
  background-color: var(--pop-up);
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  height: 450px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.model-edit-popup-close {
  margin-left: 95%;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  cursor: pointer;
}

.model-edit-popup-save {
  width: 100px;
  height: 40px;
  margin-top: 30px;
  background-color: #005cbb;
  /* outline: none; */
  border: none;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.model-edit-popup-input {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: #ff1a1a; */
}

.model-raw-details-main {
  width: 90%;
  height: 310px;
  /* background-color: yellowgreen; */
  overflow-y: scroll;
}

.model-raw-details-main::-webkit-scrollbar {
  width: 4px;
}

.model-raw-details-main::-webkit-scrollbar-thumb {
  color: gray;
}

.model-raw-add-main {
  width: 90%;
  height: 100px;
  /* background-color: #000; */
  display: flex;
  flex-direction: column;
}

.model-raw-add-head {
  width: 100%;
  /* height: 20px; */
  /* background-color: wheat; */
  display: flex;
}

.model-raw-inputs {
  display: flex;
  gap: 20px;
  align-items: center;
}

.model-raw-inputs input {
  width: 30%;
}

.model-raw-inputs select {
  width: 30%;
  height: 40px;
  background-color: transparent;
  color: gray;
  outline: none;
  border-radius: 10px;
}

.model-raw-inputs button {
  width: 100px;
  height: 40px;
  background-color: #005cbb;
  border: none;
  outline: none;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}



.model-popup-input {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: #ff1a1a; */
}

.check-popup-column-content {
  background-color: var(--pop-up);
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  height: 400px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.check-popup-column-list{
  width: 90%;
  height: 300px;
  /* background-color: yellow; */
  overflow-y: scroll;
}
.check-popup-column-list::-webkit-scrollbar {
  width: 10px;
}

.check-popup-column-list::-webkit-scrollbar-thumb {
  color: gray;
}
.check-popup-column-list ul{
  /* background-color: violet;
  display: flex;
  flex-direction: column;
  align-items: center; */
  list-style-type: none;
}
.check-popup-column-list ul li{
  display: flex;
  align-items: center;
  /* background-color: springgreen; */
  gap: 30px;
}
.check-popup-column-list ul li input{
  width: 10%;
  height: 25px;
  /* display: flex; */
  /* justify-content: space-between; */
}
.delete-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.268);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.delete-popup-content {
  background-color: var(--pop-up);
  padding: 0 20px;
  border-radius: 8px;
  width: 400px;
  height: 200px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.delete-popup-btn {
  display: flex;
  gap: 20px;
}

.delete-popup-confirm {
  width: 100px;
  height: 30px;
  border-radius: 10px;
  border: none;
  background-color: #005cbb;
  color: white;
  cursor: pointer;
}

.delete-popup-cancel {
  width: 100px;
  height: 30px;
  border-radius: 10px;
  border: none;
  background-color: #005cbb;
  color: white;
  cursor: pointer;
}

.model-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.268);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.model-popup-content {
  background-color: var(--pop-up);
  padding: 20px;
  border-radius: 8px;
  width: 700px;
  height: 450px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.model-popup-close {
  margin-left: 95%;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  cursor: pointer;
}

.model-popup-input {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: #ff1a1a; */
}

input {
  width: 70%;
  height: 40px;
  background-color: transparent;
  border: 1px solid gray;
  outline: none;
  padding-left: 15px;
  border-radius: 10px;
  color: var(--text-color);
}

.model-popup-save {
  width: 100px;
  height: 40px;
  margin-top: 30px;
  background-color: #005cbb;
  /* outline: none; */
  border: none;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.model-add-input {
  width: 90%;
  height: 50px;
  /* background-color: var(--background-hover-color); */
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.model-add-input-tax {
  width: 90%;
  display: flex;
  /* gap: 30px; */
  align-items: center;
  justify-content: space-between;
  /* background-color: #000; */
}

.model-add-input-tax input {
  width: 60% !important;

}

.tax {
  width: 30%;
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  gap: 10px;
}

.raw-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #005cbb;
  font-size: 18px;
}

.action-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.268);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.action-popup-content {
  background-color: var(--pop-up);
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  height: 200px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.action-popup-close {
  margin-left: 95%;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  cursor: pointer;
}

.action-confirm-button {
  width: 100px;
  height: 40px;
  margin-top: 10px;
  background-color: #005cbb;
  /* outline: none; */
  border: none;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.action-popup-input {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* background-color: yellow; */
  align-items: center;
}

.delete-raw-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.268);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.delete-raw-content {
  background-color: var(--pop-up);
  padding: 0 20px;
  border-radius: 8px;
  width: 400px;
  height: 200px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feature-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.268);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  
}

.feature-popup-content-main {
  background-color: var(--pop-up);
/* background-color: yellow; */
  padding: 20px;
  border-radius: 8px;
  width: 60%;
  height: 450px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* overflow-y: scroll; */
  
}

.feature-popup-content {
  width: 90%;
  height: 400px;
  /* background-color: turquoise; */
  display: flex;
  /* overflow-y: scroll; */
  gap: 10px;
}

.feature-popup-image-main{
  /* width: 50%; */
  /* height: 350px; */
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid gray;
}
.feature-popup-image{
  width: 100%;
  height: 270px;
  /* background-color: rgb(153, 205, 50); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow:hidden; */
}
.feature-popup-image img{
  width: 27rem;
}
.feature-popup-subimg-main{
  width: 100%;
  height: 70px;
  /* background-color: chocolate; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature-popup-subimg-main img{
  width: 15vh;
  cursor: pointer;
}
.Edit-Feature-Image{
  width: 100%;
  height: 80px;
  /* background-color: tan; */
  display: flex;
  align-items: end;
  justify-content: center;
}
.Edit-Feature-Image button{
  width: 200px;
  height: 35px;
  background-color: #005cbb;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}
.feature-popup-web-image{
  width: 50%;
  height: 400px;
  /* background-color: rgb(0, 255, 255); */
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 20px;
  /* margin-top: 20px; */
}
.feature-popup-list-main{
  width: 100%;
  height: 350px;
  /* background-color: darkslategrey; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 20px;
  /* margin-top: 20px; */
}
.feature-popup-list-main::-webkit-scrollbar {
  width: 1px;
}

.feature-popup-list-main::-webkit-scrollbar-thumb {
  color: rgb(255, 255, 255);

}

.feature-popup-content-buttons{
  width: 90%;
  height: 70px;
  /* background-color: chartreuse; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.edit-image{
  width: 100px;
  height: 40px;
  background-color: #005cbb;
  outline: none;
  border: none;
  border-radius: 10px;
  color: var(--text-color);
  cursor: pointer;
}
.edit-features{
  width: 100px;
  height: 40px;
  background-color: #005cbb;
  outline: none;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}
.edit-image-popup-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.268);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.edit-image-popup-content{
  background-color: var(--pop-up);
  
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  height: 450px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
}
.model-edit-img-input-main{
  height: 270px;
  /* background-color: wheat; */
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.model-edit-img-input-main ul{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.model-edit-img-input-main ul li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.model-edit-img-input{
  width: 90%;
  height: 70px;
  background-color: var(--background-color-div);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 10px;
}
.model-edit-img-input img{
  width: 5rem;
}
.edit-image-popup-buttons{
  height: 70px;
  display: flex;
  align-items: end;
  /* background-color: #ff1a1a; */
}
.edit-image-popup-buttons button{ 
  width: 100px;
  height: 35px;
  border: none;
  outline: none;
  border-radius: 20px;
  background-color: #005cbb;
  color: white;
  cursor: pointer;
}
.model-change-img-btn{
  width: 120px;
  height: 35px;
  background-color: #005cbb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* border: 1px solid gray; */
  font-size: 14px;
  color: white;
  cursor: pointer;
  /* rotate: 90deg; */
}
.model-featur-edit-img-btn{
  width: 100%;
  height: 80px;
  /* background-color: rgba(217, 123, 123, 0.3); */
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 20px;
}
.model-featur-edit-img-btn input{
  width: 60%;
}
.fa-trash{
  cursor: pointer;
}
.model-edit-feature-input-main{
  height: 270px;
  /* background-color: wheat; */
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.model-edit-feature-input-main ul{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.model-edit-feature-input-main ul li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.model-edit-feature-input-main::-webkit-scrollbar {
  width: 1px;
}

.model-edit-feature-input-main::-webkit-scrollbar-thumb {
  color: rgb(255, 255, 255);

}

.model-feature-edit-popup-content{
  background-color: var(--pop-up);
  /* padding: 20px; */
  padding: 30px;
  border-radius: 8px;
  width: 450px;
  height: 400px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.feature-save-btn{
  width: 100px;
  height: 35px;
  background-color: #005cbb;
  color: #fff;
  border: none;
  border-radius: 20px;
  margin-top: 20px;
}
.model-feature-delete-popup-list-main{
  width: 90%;
  height: 300px;
  /* background-color: yellow; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  overflow-y: scroll;
  padding: 20px ;
}

.model-feature-delete-popup-list-main::-webkit-scrollbar{
  width: 4px;
}
.model-feature-delete-popup-list-main::-webkit-scrollbar-thumb{
  background-color: var(--text-color);
}


.model-feature-delete-popup-list-main ul{
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: violet; */
  list-style-type: none;
}

.model-feature-delete-popup-list-main ul li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.model-feature-Add-popup-content{
  background-color: var(--pop-up);
  /* padding: 20px; */
  padding: 30px;
  border-radius: 8px;
  width: 450px;
  height: 270px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  
}

.model-feature-add-popup-input{
  width: 90%;
  height: 140px;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 20px;
}
.add-save-btn{
  width: 120px;
  height: 35px;
  background-color: #005cbb;
  color: #fff;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

.model-popup-select option{
  /* color: #000;
  background-color: yellow; */
}

/* @media (max-width:1370px) {
  .feature-popup-content-main{
    width: 50%;
  }
  .feature-popup-content{
    flex-direction: column;
    background-color: yellowgreen;
    align-items: center;
    justify-content: center;
    overflow-x: scroll;
  }
  .feature-popup-list-main{
    overflow: unset;
  }
} */

@media (max-width:1100px) {
  .device-main {
    width: 100%;
    margin-left: 0;
  }
}